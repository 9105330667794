//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import emailjs from 'emailjs-com';
export default {
    data() {
        return {
            uniacid:window.localStorage.getItem('uniacid'),
            //uniacid:17,
            tabBarIndex: 0,
            tabBarList: [
                {
                    label: 'ALL',
                    params: {}
                },
                {
                    label: 'addBooking',
                    params: {
                        tab: 'addBooking'
                    }
                }
			],
            total: -1,
            page: 1,
            form: {
                date: '',
                name: '',
                mobile: '',
                book_date: '',
                book_time: '',
                book_type: ''
            },
            bookingList: [],
            days: [],
            booking_time_list: [],
            //booking_num_list: []
            booking_num_list: [],
            ten_quota: false,
            twelve_quota: false,
            fourteen_quota: false,
            sixteen_quota: false
        };
    },

    created() {
        this.getBookingList();
        var today = new Date();
        for (let i = 1; i < 15; i++) {
            var day = new Date(today.getTime() + (24 * 60 * 60 * 1000 * i));
            this.days.push(day);
           // console.log("day " + i.toString() + ": " + day.getFullYear());
            if(day.getDay() == 6 || day.getDay() == 0) {
               // console.log("weekend: " + day);
            }
            
        }
        
        //console.log("days: " + this.days);
        this.isSlotFull(this.form.book_time);
        this.showElement = true
    },

    methods: {
        async isSlotFull(time) {
        let uniacid =window.localStorage.getItem('uniacid');
        //let uniacid =17;
        //10:00
        let addform = {
            book_date: this.form.book_date,
            book_time: '10:00',
            book_type: this.form.book_type,
            name: 'check',
            mobile: 'check',
            uniacid: uniacid,
            member_id: window.localStorage.getItem('user_id')
        }
        console.log('date'+this.form.book_date)
        const api = '/booking/readQuota'
        const res = await this.$http.post(api, addform)
                        console.log('res',res)
        console.log('form'+JSON.stringify(addform))
        console.log('quota check'+JSON.stringify(res))
        if (res.data.list[0] && res.data.list[0].quota) {
            if (parseInt(res.data.list[0].quota, 10) >=3) {
                console.log('int'+parseInt(res.data.list[0].quota, 10))
            }
            else {
                console.log('int'+parseInt(res.data.list[0].quota, 10))
                this.ten_quota = true
            }
        } else {
            this.ten_quota = true
        // 12:00
        }
        let addform2 = {
            book_date: this.form.book_date,
            book_time: '12:00',
            book_type: this.form.book_type,
            name: 'check',
            mobile: 'check',
            uniacid: uniacid,
            member_id: window.localStorage.getItem('user_id')
        }
        console.log('date'+this.form.book_date)
        const api2 = '/booking/readQuota'
        const res2 = await this.$http.post(api2, addform2)
                        console.log('res2',res2)
        console.log('form'+JSON.stringify(addform2))
        console.log('quota check'+JSON.stringify(res2))
        if (res2.data.list[0] && res2.data.list[0].quota) {
            if (parseInt(res2.data.list[0].quota, 10) >=2) {
                console.log('int2'+parseInt(res2.data.list[0].quota, 10))
            }
            else {
                console.log('int2'+parseInt(res2.data.list[0].quota, 10))
                this.twelve_quota = true
            }
        } else {
            this.twelve_quota = true
        }
        //14:00
        let addform3 = {
            book_date: this.form.book_date,
            book_time: '14:00',
            book_type: this.form.book_type,
            name: 'check',
            mobile: 'check',
            uniacid: uniacid,
            member_id: window.localStorage.getItem('user_id')
        }
        console.log('date'+this.form.book_date)
        const api3 = '/booking/readQuota'
        const res3 = await this.$http.post(api3, addform3)
                        console.log('res3',res3)
        console.log('form'+JSON.stringify(addform3))
        console.log('quota check'+JSON.stringify(res3))
        if (res3.data.list[0] && res3.data.list[0].quota) {
            if (parseInt(res3.data.list[0].quota, 10) >=10) {
                console.log('int3'+parseInt(res3.data.list[0].quota, 10))
            }
            else {
                console.log('int3'+parseInt(res3.data.list[0].quota, 10))
                this.fourteen_quota = true
            }
        } else {
            this.fourteen_quota = true
        }
        //16:00
        let addform4 = {
            book_date: this.form.book_date,
            book_time: '16:00',
            book_type: this.form.book_type,
            name: 'check',
            mobile: 'check',
            uniacid: uniacid,
            member_id: window.localStorage.getItem('user_id')
        }
        console.log('date'+this.form.book_date)
        const api4 = '/booking/readQuota'
        const res4 = await this.$http.post(api4, addform4)
                        console.log('res4',res)
        console.log('form'+JSON.stringify(addform4))
        console.log('quota check'+JSON.stringify(res4))
        if (res4.data.list[0] && res4.data.list[0].quota) {
            if (parseInt(res4.data.list[0].quota, 10) >=5) {
                console.log('int4'+parseInt(res4.data.list[0].quota, 10))
            }
            else {
                console.log('int4'+parseInt(res4.data.list[0].quota, 10))
                this.sixteen_quota = true
            }
        } else {
            this.sixteen_quota = true
        }
        },
        async submitForm(){
            console.log('date:' + this.form.date);
            console.log('name:' + this.form.name);
            console.log('mobile:' + this.form.mobile);
            console.log('userid:' + window.localStorage.getItem('user_id'));

            let uniacid =window.localStorage.getItem('uniacid');
            //let uniacid =17;
            let addform = {
                book_date: this.form.book_date,
                book_time: this.form.book_time,
                book_type: this.form.book_type,
                name: this.form.name,
                mobile: this.form.mobile,
                uniacid: uniacid,
                member_id: window.localStorage.getItem('user_id')
            }
            //const api = '/booking/add'
            const api = '/booking/readQuota'
            const res = await this.$http.post(api, addform)
            console.log('form'+JSON.stringify(addform))
            if (res.code == 0) {
                //this.getAddressList()
                //return this.$message({ message: res.message,type: 'warning'});
                const api2 = '/booking/add'
                const api3 = ''
                if (res.data.list[0] && res.data.list[0].quota) {
                    addform.quota = parseInt(res.data.list[0].quota, 10)+1
                    console.log('quota', res.data.list[0].quota)
                    if ((res.data.list[0].book_time == "10:00" && addform.quota > 3) || (res.data.list[0].book_time == "12:00" && addform.quota > 2) || (res.data.list[0].book_time == "14:00" && addform.quota > 10) || (res.data.list[0].book_time == "16:00" && addform.quota > 5)){
                        return this.$message({ message: '沒有空位了',type: 'warning'});
                    }
                    this.pdo = '/booking/updateQuota'
                }
                else {
                    addform.quota = 1
                    this.pdo = '/booking/insertQuota'
                }
                const res2 = await this.$http.post(api2, addform)
                console.log('form'+JSON.stringify(addform))
                if (res2.code == 0) {
                    //this.getAddressList()
                    console.log('res2',res2)
                    //quota
                    let addform3 = addform
                    console.log('date'+this.form.book_date)
                    const api3 = this.pdo
                    const res3 = await this.$http.post(api3, addform3)
                    console.log('api3',api3)
                    console.log('res3',res3)
                    console.log('form3'+JSON.stringify(addform3))
                    console.log('quota check'+JSON.stringify(res3))
                    console.log('res3.message:'+JSON.stringify(res3.message))
                    console.log('uniacid:'+uniacid)
                    let ss = res3.message;
                    if(ss == "新增預約成功" && uniacid == 17){
                        console.log('新增預約成功功功功功功功功功功功功功功功功功功功')
                        var emaillist = [
                            {
                                "uniacid": 17, //Dr.Newmann
                                "service": "service_i62illp",
                                "template": "template_mvnrop3",
                                "api": "S3LnfEKwwhrsakUQT"
                            },
                        ];

                        
                        const user_id = window.localStorage.getItem('user_id') || ''
                        let res = null
                        if(user_id){
                            res = await this.$http.get('/user/profile',{user_id})

                            //user_id && res.code == 0 && this.$store.commit('saveUserInfo',res.data)
                            console.log('saveUserInfoooooooo')
                            console.log(res.data.email)
                            //bookingemailForm.email = res.data.email;
                           // bookingemailForm.date = this.form.book_date;
                           // bookingemailForm.time = this.form.book_time;
                           // bookingemailForm.mobile = this.form.mobile;
                           // bookingemailForm.name = this.form.name;

                            let bookingemailForm = {
                                email: res.data.email,
                                date: this.form.book_date,
                                time: this.form.book_time,
                                mobile: this.form.mobile,
                                name: this.form.name
                            };

                            let data = emaillist.find(item => item.uniacid == uniacid);
                            //console.log(data.service);
                            let emailService = data.service;
                            let emailTemplate = data.template;
                            let emailApi = data.api;


                            emailjs.send(emailService, emailTemplate, bookingemailForm,
                            emailApi).then((result) => {
                                console.log('SUCCESS!', result.text);
                                this.$message({ message: '已發出預約電郵',type: 'success'});
                            }, (error) => {
                                console.log('FAILED...', error.text);
                            });
                        }
                        
                    }



                    return this.$message({ message: res2.message,type: 'warning'});
                }else{
                    return this.$message.error(res2.message)
                }
            }else{
                return this.$message.error(res.message)
            }
        },

        async getBookingList() {
            let uniacid =window.localStorage.getItem('uniacid');
            //let uniacid =17;
            const res = await this.$http.get('/booking', {
                page: this.page,
                size: 1000,
                uniacid: uniacid,
                member_id: window.localStorage.getItem('user_id')
            })
            if (res.code !== 0) {
                return this.$message.error(res.message);
            }
            this.bookingList = res.data.list
            console.log('bookingList: ');
            //console.log(res.data.list);



            const res2 = await this.$http.get('/bookingtime', {
                uniacid: uniacid,
            })
            if (res2.code !== 0) {
                console.log(res2)
                return this.$message.error(res2.message);
            }
            
            console.log('booking time: ');
            console.log(res2.data.list);

            const res3 = await this.$http.get('/booking/getnum', {
                uniacid: uniacid,
            })
            if (res3.code !== 0) {
                console.log(res3)
                return this.$message.error(res3.message);
            }
            
            console.log('booking getnum: ');
            console.log(res3.data.list);
        },
        
    },
    watch: {
        tabBarIndex:function(val){
            this.bookingList = []
            this.total = -1
            this.page = 1
            this.getBookingList()
        },
    'form.book_date'(newDate, oldDate) {
      this.ten_quota = false
      this.twelve_quota = false
      this.fourteen_quota = false
      this.sixteen_quota = false
      // Perform your logic when the book_date changes
      console.log('New book_time:', newDate);
      console.log('Old book_time:', oldDate);
      // Call your function here or perform any desired actions
      this.isSlotFull()
    }
    }
};
