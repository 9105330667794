//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Search from '@/components/Search'
import Order from './components/Order'
import AddressBox from './components/Address'
import Coupon from './components/Coupon'
import Booking from './components/Booking'
export default {
    name: 'User',
    components: {Search, Order, AddressBox, Coupon, Booking},
    data() {
        return {
            uniacid:window.localStorage.getItem('uniacid'),
            //uniacid:17,
		   webset:[],
           type : '0',
           userInfo: '',
           fb_name:window.localStorage.getItem('fb_name')
        }
    },
    created(){
		 var ss = window.localStorage.getItem('webinfo');
		 var ssarray = JSON.parse(ss)
		 console.log('ss',ss);
		 console.log('ssarray.ucid',ssarray['ucid']);
		 this.webset =  ssarray
		 
		 
        this.type = this.$route.query.type
        this.getUserInfo()
		 document.title = '个人中心'
    },
    methods: {
        async getUserInfo(e) {
            const user_id = window.localStorage.getItem('user_id') || ''
            let res = null
            if(user_id){
                res = await this.$http.get('/user/profile',{user_id})
            }
            user_id && res.code == 0 && this.$store.commit('saveUserInfo',res.data)
        },
    },
    watch: {
        '$store.state.userInfo':function(info){
            this.userInfo = info
        }
    }

}
